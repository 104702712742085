import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.scss';
import Logo from '../../atoms/Logo/Logo';
import Nav from '../../molecules/Nav/Nav';
import Languages from '../../molecules/Languages/Languages';
import HamburgerIcon from '../../atoms/HamburgerIcon/HamburgerIcon';
import '../../../styles/utilities/no-scroll.scss';

const Header = ({ activePage }) => {
  const [isHamburgerToggled, setIsHamburgerToggled] = useState(false);

  useEffect(() => {
    if (isHamburgerToggled) {
      document.body.classList.add('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isHamburgerToggled]);

  const toggleHamburgerMenu = () => {
    setIsHamburgerToggled((prevState) => !prevState);
  };

  const closeHamburgerMenu = () => {
    setIsHamburgerToggled(false);
  };

  return (
    <header className={styles.Header}>
      <Logo />

      <HamburgerIcon
        isToggled={isHamburgerToggled}
        onClick={toggleHamburgerMenu}
      />

      <div className={`
        ${styles.Menu}
        ${isHamburgerToggled ? styles.MenuToggled : ''}
      `}
      >
        <div className={styles.MenuWrapper}>
          <Nav
            activePage={activePage}
            closeMenu={closeHamburgerMenu}
          />

          <Languages />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  activePage: PropTypes.string.isRequired
};

export default Header;
