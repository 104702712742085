import PropTypes from 'prop-types';
import styles from './Button.module.scss';

const Button = ({ children, variant = 'primary' }) => {
  return (
    <button
      className={`${styles.Button} ${styles[variant]}`}
      type="button"
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary'])
};

export default Button;
