import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './DropdownButton.module.scss';
import Button from '../../molecules/Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import '../../../styles/utilities/no-scroll.scss';

const DropdownButton = ({
  btnText,
  handleChangeCheckbox,
  handleSelectAll,
  selectedOptions,
  hasSelectedAll,
  options,
  indeterminate
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  useEffect(() => {
    if (isDropdownVisible) {
      document.body.classList.add('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isDropdownVisible]);

  const buttonRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsDropdownVisible((prevState) => !prevState);
  };

  return (
    <div className={styles.DropdownButton}>
      <Button
        onClick={handleToggleDropdown}
        text={btnText}
        icon="fa-solid fa-angle-down"
        rotateIcon
        isClicked={isDropdownVisible}
        ref={buttonRef}
      />

      {isDropdownVisible ? (
        <Dropdown
          handleToggleDropdown={handleToggleDropdown}
          handleChangeCheckbox={handleChangeCheckbox}
          handleSelectAll={handleSelectAll}
          selectedOptions={selectedOptions}
          hasSelectedAll={hasSelectedAll}
          options={options}
          forwardedRef={buttonRef}
          indeterminate={indeterminate}
        />
      ) : null}
    </div>
  );
};

DropdownButton.propTypes = {
  btnText: PropTypes.string.isRequired,
  handleChangeCheckbox: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasSelectedAll: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  indeterminate: PropTypes.bool
};

export default DropdownButton;
