import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './Popup.module.scss';
import Icon from '../../atoms/Icon/Icon';

const Popup = ({ children, togglePopup, className }) => {
  const handleClick = (event) => {
    event.stopPropagation();
  };

  return ReactDOM.createPortal(
    <div
      className={styles.overlay}
      onClick={togglePopup}
    >
      <Icon
        name="fa fa-times"
        className={styles.cross}
      />

      <div
        className={className || ''}
        onClick={handleClick}
      >
        {children}
      </div>
    </div>,
    document.getElementById('popup-root')
  );
};

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  togglePopup: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default Popup;
