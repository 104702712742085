import { useTranslation } from 'react-i18next';
import Image from '../../atoms/Image/Image';
import HeroInfo from '../HeroInfo/HeroInfo';
import Arrow from '../../atoms/Arrow/Arrow';
import gracePortrait from '../../../images/grace-portrait.png';
import graceRound from '../../../images/grace-round.png';
import styles from './Hero.module.scss';
import Circle from '../../atoms/Circle/Circle';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Hero}>
      <Circle variant="solid" />

      <Image
        src={gracePortrait}
        alt={t('common.name')}
        className={styles.ImagePortrait}
      />

      <Image
        src={graceRound}
        alt={t('common.name')}
        className={styles.ImageRound}
      />

      <HeroInfo />

      <Circle variant="outlined" />

      <Arrow />
    </div>
  );
};

export default Hero;
