import PropTypes from 'prop-types';
import styles from './FolderPopupButton.module.scss';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

const FolderPopupButton = ({
  children, href, variant = 'secondary', icon
}) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={styles.FolderPopupButton}
    >
      <Button variant={variant}>
        {children}

        {icon && (
          <Icon
            name={icon}
            className={styles.Icon}
          />
        )}
      </Button>
    </a>
  );
};

FolderPopupButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  variant: PropTypes.string,
  icon: PropTypes.string
};

export default FolderPopupButton;
