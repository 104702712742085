import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './FolderPopupButtons.module.scss';
import FolderPopupButton from '../../atoms/FolderPopupButton/FolderPopupButton';
import Text from '../../atoms/Text/Text';

const FolderPopupButtons = ({ folder }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.FolderPopupButtons}>
      {folder.demo && (
        <FolderPopupButton
          href={folder.demo}
          variant="primary"
        >
          {t('projects.showDemo')}
        </FolderPopupButton>
      )}

      {folder.gitHub && (
        <FolderPopupButton
          href={folder.gitHub}
          variant="secondary"
          icon="fa-brands fa-github"
        >
          {t('projects.gitHub')}
        </FolderPopupButton>
      )}

      {folder.comingSoon && (
        <Text className={styles.ComingSoon}>
          {t('projects.comingSoon')}
        </Text>
      )}
    </div>
  );
};

FolderPopupButtons.propTypes = {
  folder: PropTypes.shape({
    gitHub: PropTypes.string,
    demo: PropTypes.string,
    comingSoon: PropTypes.bool
  }).isRequired
};

export default FolderPopupButtons;
