import PropTypes from 'prop-types';
import styles from './FolderPopupContent.module.scss';
import Image from '../../atoms/Image/Image';
import FolderPopupText from '../FolderPopupText/FolderPopupText';
import FolderPopupButtons from '../../molecules/FolderPopupButtons/FolderPopupButtons';

const FolderPopupContent = ({ folder }) => {
  return (
    <div className={styles.FolderPopupContent}>
      <div className={styles.ScrollableContainer}>
        <Image
          src={`../../../images/${folder.uri}`}
          alt={folder.name}
          className={styles.Image}
        />

        <FolderPopupText folder={folder} />

        <FolderPopupButtons folder={folder} />
      </div>
    </div>
  );
};

FolderPopupContent.propTypes = {
  folder: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired
  }).isRequired
};

export default FolderPopupContent;
