import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './FolderPopupText.module.scss';
import Heading from '../../atoms/Heading/Heading';
import Text from '../../atoms/Text/Text';

const FolderPopupText = ({ folder }) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading
        type="h2"
        className={styles.Title}
      >
        {folder.name}
      </Heading>

      <Heading
        type="h3"
        className={styles.Subtitle}
      >
        {t('projects.description')}
      </Heading>

      <Text className={styles.Text}>
        {folder.description}
      </Text>

      <Heading
        type="h3"
        className={styles.Subtitle}
      >
        {t('projects.skills')}
      </Heading>

      <Text className={styles.Text}>
        {folder.skills}
      </Text>
    </>
  );
};

FolderPopupText.propTypes = {
  folder: PropTypes.shape({
    description: PropTypes.string.isRequired,
    skills: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default FolderPopupText;
