import PropTypes from 'prop-types';
import styles from './Circle.module.scss';

const Circle = ({ variant }) => {
  return (
    <div className={variant === 'solid' ? styles.CircleSolid : styles.CircleOutlined} />
  );
};

Circle.propTypes = {
  variant: PropTypes.string.isRequired
};

export default Circle;
