import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Image from '../../atoms/Image/Image';
import styles from './Folder.module.scss';
import Icon from '../../atoms/Icon/Icon';
import Heading from '../../atoms/Heading/Heading';
import Text from '../../atoms/Text/Text';

const Folder = ({
  name, link, image, folder, setSelectedFolder, toggleFolderPopup
}) => {
  const { t } = useTranslation();

  const handleOpenPopup = () => {
    setSelectedFolder(folder);
    toggleFolderPopup();
  };

  const handleLinkClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      className={styles.Folder}
      onClick={handleOpenPopup}
    >
      <Image
        src="../../../images/frame.png"
        alt={name}
        className={styles.Background}
      />

      <Image
        src={`../../../images/${image}`}
        alt={name}
        className={styles.Image}
      />

      <div className={styles.Description}>
        <Heading
          type="h2"
          className={styles.Heading}
        >
          {name}
        </Heading>

        {link && (
          <a
            href={link}
            className={styles.Icon}
            title="GitHub"
            target="_blank"
            rel="noreferrer"
            onClick={handleLinkClick}
          >
            <Icon name="fa-brands fa-github" />
          </a>
        )}

        {folder.comingSoon && (
          <Text>
            {t('projects.comingSoon')}
          </Text>
        )}
      </div>
    </div>
  );
};

Folder.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  image: PropTypes.string.isRequired,
  folder: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    comingSoon: PropTypes.bool,
    uri: PropTypes.string.isRequired
  }).isRequired,
  setSelectedFolder: PropTypes.func.isRequired,
  toggleFolderPopup: PropTypes.func.isRequired
};

export default Folder;
