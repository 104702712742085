import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Main from '../../components/templates/Main/Main';
import Folders from '../../components/molecules/Folders/Folders';
import en from '../../localization/en';

const Projects = ({ setActivePage, setSelectedFolder, toggleFolderPopup }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setActivePage(en.projects.name);
  }, []);

  return (
    <Main
      title={t('projects.name')}
      padded
    >
      <Folders
        setSelectedFolder={setSelectedFolder}
        toggleFolderPopup={toggleFolderPopup}
      />
    </Main>
  );
};

Projects.propTypes = {
  setActivePage: PropTypes.func.isRequired,
  setSelectedFolder: PropTypes.func.isRequired,
  toggleFolderPopup: PropTypes.func.isRequired
};

export default Projects;
