import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Folder from '../../organisms/Folder/Folder';
import styles from './Folders.module.scss';

const Folders = ({ setSelectedFolder, toggleFolderPopup }) => {
  const { t } = useTranslation();
  const projectsFolders = t('projects.folders', { returnObjects: true });

  return (
    <div className={styles.Folders}>
      {projectsFolders.map((folder) => (
        <Folder
          key={folder.name}
          name={folder.name}
          link={folder.gitHub}
          image={folder.uri}
          folder={folder}
          setSelectedFolder={setSelectedFolder}
          toggleFolderPopup={toggleFolderPopup}
        />
      ))}
    </div>
  );
};

Folders.propTypes = {
  setSelectedFolder: PropTypes.func.isRequired,
  toggleFolderPopup: PropTypes.func.isRequired
};

export default Folders;
