import PropTypes from 'prop-types';
import styles from './FolderPopup.module.scss';
import Popup from '../Popup/Popup';
import Image from '../../atoms/Image/Image';
import FolderPopupContent from '../FolderPopupContent/FolderPopupContent';

const FolderPopup = ({ folder, toggleFolderPopup }) => {
  return (
    <Popup
      togglePopup={toggleFolderPopup}
      className={styles.FolderPopup}
    >
      <Image
        src="../../../images/frame.png"
        alt={folder.name}
        className={styles.Frame}
      />

      <FolderPopupContent folder={folder} />
    </Popup>
  );
};

FolderPopup.propTypes = {
  folder: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired
  }).isRequired,
  toggleFolderPopup: PropTypes.func.isRequired
};

export default FolderPopup;
