import { useState, useEffect } from 'react';
import {
  HashRouter as Router, Route, Switch, Redirect
} from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import i18n from './i18n';
import Header from './components/organisms/Header/Header';
import Home from './pages/Home/Home';
import AboutMe from './pages/AboutMe/AboutMe';
import Skills from './pages/Skills/Skills';
import Projects from './pages/Projects/Projects';
import FolderPopup from './components/organisms/FolderPopup/FolderPopup';

const App = () => {
  const [activePage, setActivePage] = useState('');
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isFolderPopupOpen, setIsFolderPopupOpen] = useState(false);

  useEffect(() => {
    const path = window.location.pathname;
    const lang = path.split('/')[1];

    if (
      lang &&
      Object.keys(i18n.options.resources).includes(lang)
    ) {
      i18n.changeLanguage(lang);
      document.title = i18n.t('common.title');
    }
  }, []);

  const toggleFolderPopup = () => setIsFolderPopupOpen((prevState) => !prevState);

  return (
    <div className="App">
      <Router>
        <Header activePage={activePage} />

        <Switch>
          {/* Routes for English */}
          <Route
            path="/en"
            exact
            render={() => <Home setActivePage={setActivePage} />}
          />

          <Route
            path="/en/about-me"
            render={() => <AboutMe setActivePage={setActivePage} />}
          />

          <Route
            path="/en/skills"
            render={() => <Skills setActivePage={setActivePage} />}
          />

          <Route
            path="/en/projects"
            render={() => (
              <Projects
                setActivePage={setActivePage}
                setSelectedFolder={setSelectedFolder}
                toggleFolderPopup={toggleFolderPopup}
              />
            )}
          />

          {/* Routes for the default language */}
          <Route
            path="/"
            exact
            render={() => <Home setActivePage={setActivePage} />}
          />

          <Route
            path="/about-me"
            render={() => <AboutMe setActivePage={setActivePage} />}
          />

          <Route
            path="/skills"
            render={() => <Skills setActivePage={setActivePage} />}
          />

          <Route
            path="/projects"
            render={() => (
              <Projects
                setActivePage={setActivePage}
                setSelectedFolder={setSelectedFolder}
                toggleFolderPopup={toggleFolderPopup}
              />
            )}
          />

          {/* For other paths, redirect to Home */}
          <Route
            path="/"
            render={() => <Redirect to="/" />}
          />
        </Switch>
      </Router>

      {isFolderPopupOpen && (
        <FolderPopup
          folder={selectedFolder}
          toggleFolderPopup={toggleFolderPopup}
        />
      )}
    </div>
  );
};

export default App;
