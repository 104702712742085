import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './Nav.module.scss';
import NavItem from '../../atoms/NavItem/NavItem';
import en from '../../../localization/en';

const Nav = ({ activePage, closeMenu }) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.Nav}>
      <NavItem
        item={t('home.name')}
        page="/"
        onClick={closeMenu}
        isActive={activePage === en.home.name}
      />

      <NavItem
        item={t('aboutMe.name')}
        page="/about-me"
        onClick={closeMenu}
        isActive={activePage === en.aboutMe.name}
      />

      <NavItem
        item={t('skills.name')}
        page="/skills"
        onClick={closeMenu}
        isActive={activePage === en.skills.name}
      />

      <NavItem
        item={t('projects.name')}
        page="/projects"
        onClick={closeMenu}
        isActive={activePage === en.projects.name}
      />
    </ul>
  );
};

Nav.propTypes = {
  activePage: PropTypes.string.isRequired,
  closeMenu: PropTypes.func.isRequired
};

export default Nav;
